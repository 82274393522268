.component-alarm-config{
    &-icon{
        font-size: 20px;
        margin-top: 14px;
        color: #fff;
    }
    &-overlay{
        .row{
            display: flex;
            justify-content: space-between;
            width: 240px;
            &+.row{
                margin-top: 8px;
            }
        }
        .ant-slider{
            width: 144px;
            margin: 4px 7px 4px 0 !important;
        }
    }
}
@primary-color: #3370FF;