@import '@/styles/variable.less';

.popover {
    position: relative;
    width: 336px;
}

.noticeButton {
    display: inline-block;
    cursor: pointer;
    transition: all 0.3s;
}

.icon {
    padding: 4px;
    vertical-align: middle;
}

.badge {
    font-size: 16px;
    color: #FFFFFF !important;
}

.tabs {
    :global {
        .ant-tabs-nav-list {
            margin: auto;
        }

        .ant-tabs-nav-scroll {
            text-align: center;
        }

        .ant-tabs-bar {
            margin-bottom: 0;
        }
    }
}

.alertDrawer {
    .title {
        color: #333333;
        font-size: 18px;
        font-family: PingFangSC-Bold, PingFang SC, sans-serif;
        font-weight: bold;
    }

    :global .ant-drawer-header-title {
        span {
            color: #000 !important;
        }
    }
}

.alertNotification {
    width: 500px !important;

    :global .ant-notification-notice-close {
        top: 8px !important;
        right: 8px !important;
    }
}

@primary-color: #3370FF;