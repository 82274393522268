
.alertInfo {
    position: absolute;
    overflow: hidden;
    word-wrap: break-word;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
    z-index: 99;
}

.close {
    position: absolute;
    right: 12px;
    top: 8px;
    cursor: pointer;
}

.alertItem {
    width: 100%;

    .title {
        display: flex;

        .text {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC, sans-serif;
            font-weight: 500;
            color: #E50012;
            line-height: 20px;
            text-shadow: 0px 2px 4px rgba(170, 170, 170, 0.5);

            &:before {
                content: url("./assets/point.svg");
                padding-right: 8px;
            }
        }

        .extra {
            margin-left: auto;
            color: #999999;
            text-shadow: 0px 2px 4px rgba(170, 170, 170, 0.5);
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC, sans-serif;
        }
    }

    .content {
        padding: 8px 0px 8px 16px;
        font-family: PingFangSC-Regular, PingFang SC, sans-serif;
        font-weight: 400;
        color: #333333;
        text-shadow: 0px 2px 4px rgba(170, 170, 170, 0.5);

        .col {
            display: flex;

            .right {
                margin-left: auto;
            }
        }

        .tbar {
            text-align: right;
            color: #3370FF;
            cursor: pointer;
        }
    }
}

.alarmAttachment {
    display: flex;
    justify-content: center;
    align-items: center;

    .item {
        cursor: pointer;
        margin: 0 12px;
    }
}
@primary-color: #3370FF;