@import "@/styles/theme";

// 获取尺寸 基础尺寸为4px
@function get-size($type) {
    @return $SIZE1 * $type;
}

body {
    margin: 0;
    font-family: PingFang-SC-Bold, PingFang-SC, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;

    main,
    main.ant-pro-basicLayout-content {
        min-width: 1660px;
        //min-height: 700px;
        background: #f0f2f5;
    }

    #root {
        height: 100%;
        background: #F4F7FA;

        .pc-layout-basic {
            div[attr-hoc] {
                height: calc(100% - 40px);
            }
        }

        div[attr-hoc] {
            height: 100%;
        }
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace, sans-serif;
}

.ant-btn-primary {
    background: #3370FF;
}

::-webkit-scrollbar {
    width: get-size(1);
    height: get-size(2);
}

::-webkit-scrollbar-track {
    background-color: $scrollbar-background;
    -webkit-border-radius: get-size(1);
    -moz-border-radius: get-size(1);
    border-radius: get-size(1);
}

// 滚动条样式
.app-scrollbar {
    overflow: auto;
}


.app-scrollbar::-webkit-scrollbar {
    width: get-size(1);
}

::-webkit-scrollbar-thumb {
    background-color: $scrollbar-color;
    -webkit-border-radius: get-size(1);
    -moz-border-radius: get-size(1);
    border-radius: get-size(1);
}

//<!--修改 滚动条的 下面 的 样式-->
.app-scrollbar::-webkit-scrollbar-track {
    background-color: $scrollbar-background;
    -webkit-border-radius: get-size(1);
    -moz-border-radius: get-size(1);
    border-radius: get-size(1);
}

//<!--修改 滑块 -->
.app-scrollbar::-webkit-scrollbar-thumb {
    background-color: $scrollbar-color;
    -webkit-border-radius: get-size(1);
    -moz-border-radius: get-size(1);
    border-radius: get-size(1);
}

// 去掉搞得底图logo
.amap-logo,
.amap-copyright {
    display: none !important;
}

// popover设置最小宽度，避免小屏文字换行问题
.ant-popover-content {
    min-width: 170px;
}

.component-tree-node {
    position: relative;
    width: 100%;
    padding-bottom: 0px;
    min-height: 40px;

    // 冲掉hover样式
    .ant-tree-node-content-wrapper{
        display: flex;
    }
    .ant-tree-node-content-wrapper-normal {
        width: 0 !important;
        display: flex;

        &:hover {
            background-color: transparent !important;
        }
    }

    .ant-tree-treenode-disabled:first-child {
        padding-left: 8px !important;
    }

    .ant-tree-treenode {
        // 设置高度太小 影响虚拟滚动计算
        padding-bottom: 2px !important;
        padding-top: 2px !important;

        //height: 24px !important;
        //line-height: 24px !important;
        //padding-bottom: 0 !important;
        //&+.ant-tree-treenode{
        //    margin-top: 4px;
        //}

        .ant-tree-title {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
        }

        &:hover {
            background-color: rgba(216, 230, 255, 0.5) !important;
        }

        .ant-tree-node-content-wrapper {
            background-color: transparent !important;

            & :hover {
                background-color: transparent !important;
            }
        }
    }

    .ant-tree-node-selected {
        background-color: transparent !important;
    }

    .ant-tree-treenode-selected {
        background-color: #D8E6FF;
    }

    .ant-tree-switcher_close {
        .tree-icon-open {
            display: none !important;
        }
    }

    .ant-tree-switcher_open {
        .tree-icon-close {
            display: none !important;
        }
    }

    .ant-tree-title {
        width: 0;
        flex-grow: 1;
        display: flex;
    }

    .ant-tree-switcher-noop+.ant-tree-node-content-wrapper>.ant-tree-title{
        margin-left: -24px;
        display: flex;
        >*:not(.ml-24){
            padding-left: 24px;
        }
    }

    .device-icon{
        width: 24px;
        height: 24px;
        margin-right: 4px;
    }

    .ellipsis{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .flex-grow{
        width: 0;
        flex-grow: 1;
    }

    .flex{
        display: flex;
    }

    .ant-badge-status-dot{
        width: 10px;
        height: 10px;
    }

    &-reload {
        position: absolute;
        top: 0px;
        right: 12px;
        cursor: pointer;
    }
}

.component-tree-drawer{
    &-drawer{
        .ant-drawer-body{
            display: flex;
            flex-direction: column;
        }
        .component-tree-node{
            height: 0;
            flex-grow: 1;
            overflow-y: auto;
        }
        .ant-descriptions{
            padding: 8px !important;
            border-top: 1px solid #dedede;
        }
    }
}

.component-tree-date-picker{
    border-top: 1px solid #dedede;
    padding: 8px 8px 0;
    text-align: right;
    display: flex;
    gap: 8px;

    .ant-picker-input > input {
        font-size: 12px !important;
    }


    .timeLabel {
        font-size: 14px;
        font-family: PingFang-SC-Bold, PingFang-SC, sans-serif;
        font-weight: bold;
        color: #000000;
        line-height: 24px;
    }
}

.bg_3270ff{
    background: #3270FF;
}
.bg_00b868{
    background: #00b868;
}
.bg_637bf7{
    background: #637bf7;
}
.bg_ffb000{
    background: #ffb000;
}
.bg_00c8c8{
    background-color: #00c8c8;
}
.bg_08c060{
    background: #08c060;
}
.bf_9080ff{
    background: #9080FF;
}
