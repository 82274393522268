.modal {
    :global .ant-modal-close {
        display: none;
    }
}

.form {
    :global .ant-row {
        margin-bottom: 24px;
    }
}
@primary-color: #3370FF;