@import '@/styles/variable.less';

.editColumn {
    // 冲掉查询样式
    :global .ant-col-offset-4 {
        margin-left: 0px !important;
        text-align: left !important;
    }

    // 冲掉查询样式
    :global .ant-pro-table-list-toolbar {
        position: absolute;
        right: 0px;
        top: -72px;
    }

    :global .ant-pro-table-search {
        background-color: transparent !important;
        margin-bottom: 0px !important;

        :global .ant-form-item {
            margin-bottom: 24px !important;
        }

        :global .ant-input-affix-wrapper, .ant-select-selector {
            border: 1px solid @border-color-base !important;
        }
    }

    :global .ant-card {
        margin: 0 0px;
        border-radius: 8px;
    }
}


.imageComponent {
    @height: 12px;

    & li {
        height: @height !important;

        & button {
            height: @height !important;
        }
    }
}
@primary-color: #3370FF;