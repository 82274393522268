@import '@/styles/variable.less';

.ant-layout-footer {
    position: absolute;
    background-color: rgba(0, 0, 0, 0);
    min-width: 300px;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 0px;
    padding: 0px;

    .ant-pro-global-footer {
        display: flex;
        flex-direction: row-reverse;
        margin: 0px;


        .ant-pro-global-footer-copyright {
            margin-right: 24px;
        }

        div, span, a {
            color: rgba(255, 255, 255, 0.4);
        }

        a:hover {
            color: #ffffff;

            span, img {
                color: #ffffff;
            }
        }
    }
}
@primary-color: #3370FF;