@import '@/styles/variable.less';

.pc-layout-basic {
    .ant-pro-sider-links {
        display: none;

        .ant-menu-title-content {
            display: none;
        }
    }
}

.layoutMenuItem {
    a {
        color: @menu-highlight-color !important;
    }

    &:hover {
        a {
            color: @menu-highlight-color !important;
        }
    }
}

.ant-menu-dark {
    .layoutMenuItem {
        a {
            color: @menu-dark-color !important;
        }

        &:hover {
            a {
                color: @layout-trigger-color !important;
            }
        }
    }
}

// 覆盖菜单缩起样式
.ant-menu-inline-collapsed {
    .ant-menu-submenu-title {
        width: 100%;
    }

    .ant-menu-submenu-title, .ant-pro-menu-item-title {
        display: inline-block;
        //opacity: 0;
    }

    .layoutMenuItem {
        a {
            //opacity: 0;
        }
    }
}

.ant-pro-sider-logo {
    padding: 6px 0px;

    h1 {
        font-size: 16px;
    }
}

.ant-pro-global-header, .ant-pro-global-header-layout-mix, .ant-pro-global-header-logo {
    background: #002140 !important;
    background-color: #002140 !important;
}

.ant-pro-global-header-logo {
    margin-left: -16px;
    padding-left: 16px;
    //margin-right: 16px;
    min-width: 208px;

    img {
        min-height: 24px !important;
        height: auto !important;
        max-height: 36px;
    }

    h1 {
        margin-left: 4px !important;
        //width: 156px !important;
        font-weight: normal !important;
        font-size: 15px !important;
    }
}

.ant-pro-global-header {
    .ant-breadcrumb {
        span {
            pointer-events: none;

            a {
                color: #ffffff !important;
                font-family: PingFang-SC-Bold, PingFang-SC, sans-serif;
                font-weight: bold;
            }
        }

        .ant-breadcrumb-separator {
            color: #ffffff !important;
        }
    }
}

.center-layout {

}

.home-tabs {
    width: 100%;
    height: 40px;
    background: #F4F7FA;
    position: relative;
    z-index: 2;
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.16);

    .ant-tabs {
        height: 40px;
        background: #FFFFFF;
        color: #4B4B4B !important;

        .ant-tabs-nav-wrap {
            background: #FFFFFF !important;
        }
    }

    .ant-layout {
        background: #F4F7FA !important;
    }

    .ant-tabs-top > .ant-tabs-nav {
        background: #fff;
        height: 40px;
    }

    .ant-tabs, .ant-tabs-content {
        height: 100%;
    }

    .ant-tabs-tab {
        color: #4B4B4B !important;
        background-color: #fff !important;
        border: 0px solid #fff !important;
    }

    .ant-tabs-tab-active {
        background: url("./tagBg.svg") !important;
        background-size: cover !important;
    }

    .ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #4B4B4B !important;
    }

    .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
        background: #fff;
    }

    .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
        background: #F0F2F5;
        border-bottom-color: #F0F2F5;
    }

}

.ant-pro-menu-item, .layoutMenuItem {
    span.anticon {
        font-size: 20px !important;
    }
}

.flex-box {
    display: flex;
    display: -ms-flex;
    align-items: center;
    -webkit-align-items: center;
}

.flex-box-column {
    display: flex;
    display: -ms-flex;
    flex-direction: column;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

@primary-color: #3370FF;