// antd 组件 只定义配置类模块相关信息
@import '~antd/dist/antd.less';
//@import '~antd/dist/antd.compact.less'; // 引入官方提供的紧凑 less 样式入口文件
//@import '~antd/dist/antd.dark.less'; // 引入官方提供的暗色 less 样式入口文件
// 变量
@import './variable.less';

.ant-pro-basicLayout-content {
    margin: 0px !important;
}

.ant-tabs-top > .ant-tabs-nav {
    margin: 0 0 0 0 !important;
}

.ant-tabs-tab-active {
    .ant-tabs-tab-btn {
        color: @current-color !important;
    }
}

// 提交按钮颜色
.ant-btn-primary {
    &:hover,
    &:focus {
        background-color: @bth-primary-hover;
        border-color: @bth-primary-hover;
    }
}

// 重置按钮
.ant-btn-rest {
    background-color: @bth-rest-color !important;
    color: #FFFFFF;

    &:hover,
    &:focus {
        background-color: @bth-rest-hover-color !important;
        border-color: @bth-rest-hover-color !important;
        color: #FFFFFF !important;
    }
}

.ant-menu-item-selected {
    color: @current-color !important;

    &:after {
        border-bottom: 2px solid @current-color;
        margin: 0 -6px;
    }
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: @current-color !important;
}

// 冲掉icon样式
.ant-space-item {
    .ant-btn:has(.ant-btn-link) {
        border-color: @current-color !important;
    }
}

.ant-space-item {
    .ant-btn-link {
        border-color: @current-color !important;
    }
}

.ant-table-cell {
    .ant-btn-link {
        border-color: @current-color !important;
    }
}

// 处理pro-table search区域
.ant-pro-table-search {
    @pro-table-boarder-color: #D9D9D9;

    .ant-input-affix-wrapper {
        border-color: @pro-table-boarder-color; // 边框颜色
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border-color: @pro-table-boarder-color; // 边框颜色
    }
}

// 冲掉modal
.ant-card-body {
    .ant-modal-wrap {
        pointer-events: none !important;
    }
}

// 带icon按钮样式
.icon-btn-style {
    display: flex;
    align-items: center;
}

// table 行样式
.ant-btn-text {
    color: @current-color;
}

// 抽屉样式
.ant-drawer-close {
    position: absolute;
    right: 20px;
}

.drawer-footer {
    width: 100%;
    text-align: center;

    .ant-btn {
        width: 100px;
        height: 30px;
        border-radius: 4px;
    }
}

.ant-drawer-footer {
    display: flex;
    justify-content: center;
}

.modal-footer-right {
    .ant-btn {
        margin-right: 5px;
    }
}

.ant-steps-item-finish {
    .ant-steps-item-container {
        .ant-steps-item-content {
            .ant-steps-item-title::after {
                background-color: #52C41A !important;
            }
        }
    }
}

.ant-steps-item-icon {
    border-color: #52C41A !important;
}

.ant-steps-item-finish {
    .ant-steps-item-icon {
        .ant-steps-icon {
            color: #52C41A !important;
        }
    }
}

.ant-steps-item-process {
    .ant-steps-item-container {
        .ant-steps-item-icon {
            background-color: #52C41A !important;
        }
    }
}

// tabs
.alarm-mangage {
    padding: 10px;
    height: 100%;

    .ant-tabs {
        height: calc(100% - 40px);
    }

    .ant-tabs-content-holder {
        background: #FFFFFF;
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
        border: 2px solid;
        border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1)) 2 2;
    }

    .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-tab {
        width: 120px;
        height: 36px;
        background: linear-gradient(180deg, #E3EAF3 0%, #F7F9FB 100%);
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
        border-radius: 4px;
        border: 2px solid;
        border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1)) 2 2;
        margin-bottom: 5px;
        justify-content: center;
    }

    .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-tab-active {
        height: 41px;
        margin-bottom: 0px;
        padding-bottom: 5px;
        background: #fff;
    }

    .ant-tabs-top > .ant-tabs-nav {
        margin: 0 !important;
    }

    .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab, .ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab, .ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
        margin-left: 5px;
    }
}

// 处理disabled样式
.ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:active {
    background: #929498 !important;
    color: #fff !important;
}

// 特殊情况下 disabled 配置
.modal-footer {
    .ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:active {
        background: #929498 !important;
        color: #fff !important;
    }
}

.screen-map {
    // 防止白屏
    .amap-layer {
        background-color: rgba(1, 16, 48, 1) !important;
    }
}


.special-tab-wrapper {
    .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-tab {
        width: 120px;
        height: 36px;
        background: linear-gradient(180deg, #E3EAF3 0%, #F7F9FB 100%);
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
        border-radius: 4px;
        border: 2px solid;
        border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1)) 2 2;
        margin-bottom: 5px;
        justify-content: center;
    }

    .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-tab-active {
        height: 41px;
        margin-bottom: 0px;
        padding-bottom: 5px;
        background: #fff;
    }

    .ant-tabs-top > .ant-tabs-nav {
        margin: 0 !important;
    }

    &.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
    &.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
    &.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
    &.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
        margin-left: 5px;
    }

    .ant-tabs-content-holder {
        background: #FFFFFF;
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
        border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1)) 2 2;
    }
}

.ellipsis {
    word-wrap: break-word;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
    max-width: 100%;
}

// css变量
html {
    --loading-main-color: @primary-color;
}
@primary-color: #3370FF;