* {
    font-family: Microsoft Yahei, Arial, sans-serif
}

@font-face {
    font-family: 'fontnameRegular';
    src: url('../DINbeMed.ttf');
}

@font-face {
    font-family: 'LED';
    src: url('../Digital-Dismay-1.otf');
}