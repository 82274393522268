

.options-by{
    height:210px;
    display: flex;
    .left-btn{
        height:100%;
        padding:10px;
        width: 240px;
        .byn-list{
            margin:0 15px 30px 0;
        }
    }
    .right-btn{
        width: calc(100% - 200px);
        height:100%;
        .titles{
            display: inline-block;
            margin-bottom: 10px;
        }
    }
}
.data-makein{
    display: flex;
    height:100%;
    padding:10px;
    .alarm-mangage-checkbox{
        flex: 1;
        .ant-checkbox-group{
            display: grid;
        }
    }
    .ant-checkbox-group-item{
        margin-bottom: 10px;
    }
    .ant-radio-wrapper{
        margin-bottom: 10px;
    }
    .alarm-mangage-radio{
        flex: 1;
        .ant-radio-group{
            display: grid;
        }
    }
    .alarm-mangage-info{
        flex: 2;
    }
}


